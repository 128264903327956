import React, { Component } from 'react';
import cx from 'classnames';
import Markdown from 'react-remarkable';
import * as AppActions from '../../actions/AppActions';
import AppStore from '../../store/AppStore';

// import './styles.scss';

function scrollIt(destination, duration = 200, easing = 'linear', callback) {
  const easings = {
    linear(temp) {
      const t = temp;
      return t;
    },
    easeInQuad(temp) {
      const t = temp;
      return t * t;
    },
    easeOutQuad(temp) {
      const t = temp;
      return t * (2 - t);
    },
    easeInOutQuad(temp) {
      const t = temp;
      return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
    },
    easeInCubic(temp) {
      const t = temp;
      return t * t * t;
    },
    easeOutCubic(temp) {
      let t = temp;
      return (t -= 1) * t * t + 1;
    },
    easeInOutCubic(temp) {
      const t = temp;
      return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
    },
    easeInQuart(temp) {
      const t = temp;
      return t * t * t * t;
    },
    easeOutQuart(temp) {
      let t = temp;
      return 1 - (t -= 1) * t * t * t;
    },
    easeInOutQuart(temp) {
      let t = temp;
      return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * (t -= 1) * t * t * t;
    },
    easeInQuint(temp) {
      const t = temp;
      return t * t * t * t * t;
    },
    easeOutQuint(temp) {
      let t = temp;
      return 1 + (t -= 1) * t * t * t * t;
    },
    easeInOutQuint(temp) {
      let t = temp;
      return t < 0.5
        ? 16 * t * t * t * t * t
        : 1 + 16 * (t -= 1) * t * t * t * t;
    },
  };

  const start = window.pageYOffset;
  const startTime =
    'now' in window.performance ? performance.now() : new Date().getTime();

  const documentHeight = Math.max(
    document.body.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.clientHeight,
    document.documentElement.scrollHeight,
    document.documentElement.offsetHeight
  );
  const windowHeight =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.getElementsByTagName('body')[0].clientHeight;
  const destinationOffset =
    typeof destination === 'number' ? destination : destination.offsetTop;
  const destinationOffsetToScroll = Math.round(
    documentHeight - destinationOffset < windowHeight
      ? documentHeight - windowHeight
      : destinationOffset
  );

  if ('requestAnimationFrame' in window === false) {
    window.scroll(0, destinationOffsetToScroll);
    if (callback) {
      callback();
    }
    return;
  }

  function scroll() {
    const now =
      'now' in window.performance ? performance.now() : new Date().getTime();
    const time = Math.min(1, (now - startTime) / duration);
    const timeFunction = easings[easing](time);
    window.scroll(
      0,
      Math.ceil(timeFunction * (destinationOffsetToScroll - start) + start)
    );

    if (window.pageYOffset === destinationOffsetToScroll) {
      if (callback) {
        callback();
      }
      return;
    }

    requestAnimationFrame(scroll);
  }

  scroll();
}

class Hero extends Component {
  constructor() {
    super();
    this.state = { ...AppStore.getConfig() };
    this.onHighlightColorChange = this.onHighlightColorChange.bind(this);
    this.onBackgroundColorChange = this.onBackgroundColorChange.bind(this);
    this.fluxStateChange = this.fluxStateChange.bind(this);
    this.onScrollDown = this.onScrollDown.bind(this);

    this.isProduction = process.env.NODE_ENV === 'production';
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    AppStore.on('change', this.fluxStateChange);
  }

  componentWillUnmount() {
    AppStore.removeListener('change', this.fluxStateChange);
  }

  onHighlightColorChange = (e) => {
    AppActions.changeHighlightColor(e.target.value);
  };

  onBackgroundColorChange = (e) => {
    AppActions.changeBackgroundColor(e.target.value);
  };

  onLightContentChange = () => {
    AppStore.toggleLightContent();
  };

  onScrollDown = () => {
    // console.log('native scroll down');
    if (typeof window === 'object') {
      scrollIt(window.innerHeight);
    }
  };

  fluxStateChange() {
    this.setState(AppStore.getConfig());
  }

  render() {
    const {
      pageSpecs: {
        lightContent,
        heroImage,
        subtitle,
        title,
        description,
        highlight,
        background,
      },
    } = this.state;

    const getImage = (key) => {
      if (AppStore.config.images[key] !== undefined) {
        return AppStore.config.images[key];
      }
      console.error(`no image with key "${key}" found`);
      return false;
    };

    return (
      <div id="hero">
        <div
          id="hero-styles"
          className={cx({
            'scale-onscroll': true,
            'opacity-onscroll': true,
            'light-content': lightContent,
          })}
        >
          {getImage(heroImage) && (
            <div
              id="hero-bg-image"
              style={{
                backgroundImage: `url(${getImage(heroImage).src})`,
              }}
            >
              <div id="grain-hero" />
            </div>
          )}

          <div id="hero-caption" className="">
            <div className="inner">
              <div className="hero-subtitle">
                {subtitle &&
                  subtitle
                    .split(' ')
                    .map((word, index) => (
                      <span key={`hero-subtitle-${index}`}>{word} </span>
                    ))}
              </div>

              <h1 className="hero-title">
                {title &&
                  title
                    .split(' ')
                    .map((word, index) => (
                      <span key={`hero-title-${index}`}>{word} </span>
                    ))}
              </h1>
              {description && <Markdown>{description}</Markdown>}

              {!this.isProduction && (
                <div>
                  <input
                    type="color"
                    value={highlight}
                    onChange={this.onHighlightColorChange}
                  />
                  <input
                    type="color"
                    value={background}
                    onChange={this.onBackgroundColorChange}
                  />
                  <input
                    type="checkbox"
                    value={lightContent}
                    onChange={this.onLightContentChange}
                  />
                </div>
              )}
              <div
                className="scrolldown"
                onClick={this.onScrollDown}
                onKeyDown={(e) => {
                  if (e.key === 'ArrowDown') {
                    this.onScrollDown();
                  }
                }}
                role="button"
                tabIndex={0}
              >
                <i className="fa fa-angle-double-down" aria-hidden="true" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Hero;

// Hero.propTypes = {
//   subtitle: PropTypes.string,
//   title: PropTypes.string,
//   heroImage: PropTypes.string,
// };

// Hero.defaultProps = {
//   subtitle: ' ',
//   title: ' ',
// };
