import React from 'react';

// import './styles.scss';

const FilterToggle = () => (
  <div id="page-action-holder" data-tooltip="View Filters" data-placement="top">
    <div id="open-filters">
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="50px"
        height="50px"
        viewBox="0 0 80 80"
        xmlSpace="preserve"
      >
        <circle className="circle-action is-inner" cx="40" cy="40" r="36" />
        <circle className="circle-action is-outer" cx="40" cy="40" r="36" />
      </svg>
      <i className="fa fa-eye" aria-hidden="true" />
    </div>
  </div>
);

export default FilterToggle;
