import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import cx from 'classnames';
import findIndex from 'lodash/findIndex';
import ReactGA from 'react-ga';
import AppStore from '../store/AppStore';
import * as AppActions from '../actions/AppActions';
import BackButton from '../components/BackButton/BackButton.js';
import Filter from '../components/Filter/Filter.js';
import FilterToggle from '../components/FilterToggle/FilterToggle.js';
import Footer from '../components/Footer/Footer.js';
import Header from '../components/Header/Header.js';
import Hero from '../components/Hero/Hero.js';
import Menu from '../components/Menu/Menu.js';
import PageOverlay from '../components/PageOverlay/PageOverlay.js';

import './styles.scss';
import '../css/style.scss';

export default class LayoutWithData extends React.Component {
  constructor() {
    super();
    this.setPageSpecs = this.setPageSpecs.bind(this);
    this.state = { ...AppStore.getConfig() };
    if (typeof window === 'object') {
      // console.log(window.location)
      AppActions.setLocation(window.location);
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const {
      data: {
        images: { edges },
      },
    } = this.props;
    const images = edges.reduce(
      (obj, cur, _i) => ({
        ...obj,
        [cur.node.sizes.originalName.substr(
          0,
          cur.node.sizes.originalName.length - 4
        )]: cur.node.sizes,
      }),
      {}
    );

    AppActions.storeImages(images);
    this.setPageSpecs(this.props);
    AppStore.on('change', () => {
      this.setState(AppStore.getConfig());
    });
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillUpdate(nextProps, _nextState) {
    const { location: { pathname = null } = null } = this.props;
    if (pathname !== nextProps?.location?.pathname) {
      this.setPageSpecs(nextProps);
    }
  }

  componentDidUpdate() {
    if (typeof window === 'object' && window.location.pathname === '/') {
      window.HeroSection();
      window.FullPage();
    }
  }

  componentWillUnmount() {}

  setPageSpecs() {
    const { location } = this.props;

    const { data } = this.props;
    const { edges: posts } = data.allMarkdownRemark;
    const contentIndex = findIndex(
      posts,
      // object => object.node.frontmatter.path === "/"
      (object) => object.node.frontmatter.path === location.pathname
    );
    const page = {
      title:
        contentIndex !== -1
          ? posts[contentIndex].node.frontmatter.title
          : data.site.siteMetadata.index.title,
      subtitle:
        contentIndex !== -1
          ? posts[contentIndex].node.frontmatter.subtitle
          : data.site.siteMetadata.index.subtitle,
      heroImage:
        contentIndex !== -1
          ? posts[contentIndex].node.frontmatter.heroImage
          : data.site.siteMetadata.index.heroImage,
      lightContent:
        contentIndex !== -1
          ? posts[contentIndex].node.frontmatter.lightContent
          : data.site.siteMetadata.index.lightContent,
      description:
        contentIndex !== -1 ? '' : data.site.siteMetadata.index.description,
    };
    AppActions.setPageSpecs(page);
  }

  render() {
    if (typeof window === 'object') {
      ReactGA.initialize('UA-78045449-12');
      ReactGA.ga('set', 'anonymizeIp', true);
    }

    const {
      colors,
      pageSpecs,
      location: { pathname },
    } = this.state;

    const { data, children } = this.props;
    const { edges: posts } = data.allMarkdownRemark;
    const isRoot = pathname === '/';

    return (
      <div>
        <Helmet
          title="Pia Flechtner – Styling Hamburg"
          meta={[
            {
              name: 'description',
              content:
                'Pia Flechtner ♥ Styling – based in Hamburg – props ✓ interior | production | set design ',
            },
          ]}
          link={[
            {
              href:
                'https://fonts.googleapis.com/css?family=Roboto:600,400,300',
              rel: 'stylesheet',
              type: 'text/css',
            },
            {
              href: 'https://fonts.googleapis.com/css?family=Poppins:500,600',
              rel: 'stylesheet',
              type: 'text/css',
            },
            {
              rel: 'apple-touch-icon',
              sizes: '180x180',
              href: '/apple-touch-icon.png',
            },
            { name: 'msapplication-TileColor', content: '#ffffff' },
            {
              name: 'msapplication-TileImage',
              content: '/mstile-150x150.png',
            },
            { name: 'theme-color', content: '#ffffff' },
            {
              rel: 'mask-icon',
              href: '/safari-pinned-tab.svg',
              color: '#FF00B2',
            },

            {
              rel: 'icon',
              type: 'image/png',
              sizes: '192x192',
              href: '/android-chrome-192x192.png',
            },
            {
              rel: 'icon',
              type: 'image/png',
              sizes: '512x512',
              href: '/android-chrome-512x512.png',
            },

            {
              rel: 'icon',
              type: 'image/png',
              sizes: '32x32',
              href: '/favicon-32x32.png',
            },

            {
              rel: 'icon',
              type: 'image/png',
              sizes: '16x16',
              href: '/favicon-16x16.png',
            },

            {
              rel: 'manifest',
              href: '/manifest.json',
            },
          ]}
        />
        <PageOverlay />

        <main>
          <div className="cd-main-content">
            <div
              id="page-content"
              className={cx({
                'light-content': pageSpecs.lightContent,
                'color-content': false,
                'grain-page': true,
              })}
              style={{
                '--background-color': colors.background,
                '--text-color': colors.highlight,
              }}
            >
              <Header title={data.site.siteMetadata.title} />
              <Menu />

              <Filter posts={posts} />

              <div
                id="main"
                className={cx({
                  hidden: false,
                })}
              >
                {isRoot && (
                  <div>
                    <Hero />
                    <div id="hero-height" />
                  </div>
                )}

                {children}
                <Footer>{isRoot ? <FilterToggle /> : <BackButton />}</Footer>
              </div>
            </div>
          </div>
        </main>
        <div className="cd-cover-layer" />
      </div>
    );
  }
}

LayoutWithData.propTypes = {
  children: PropTypes.object,
  data: PropTypes.object,
  location: PropTypes.object,
};
