import PropTypes from 'prop-types';
import React from 'react';
import FooterNavigation from '../FooterNavigation/FooterNavigation.js';
// import './styles.scss';

const Footer = ({ children }) => (
  <footer id="grain-footear" className="hidden">
    {children}

    <FooterNavigation />
    <div className="footer-info">
      Övelgönne 45, 22605 Hamburg
      <br />
      <br />
      +49 177 4672602
      <br />
      <a className="link" href="mailto:post@piaflechtner.de">
        post@piaflechtner.de
      </a>
    </div>
  </footer>
);

export default Footer;

Footer.propTypes = {
  children: PropTypes.object,
};
